<template>
  <FocusTrap>
    <div class="card">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Distributor information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <p/>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Distributor Name" v-if="distributor" v-model="distributor.name" autocomplete="off">
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Street:</label>
          <div class="col-md-10">
            <textarea rows="3" class="form-control" placeholder="Enter address here" v-if="distributor" v-model="distributor.address"  ></textarea>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Area:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="distributor" v-model="distributor.area_id">
              <option value="1" selected>Primary</option>
              <option v-for="area in areas" v-bind:value="area.id">
                {{ area.name }}
              </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Type:</label>
          <div class="col-md-2">
            <select class="form-control" v-if="distributor" v-model="distributor.gstintype" >
              <option value="1">Regular</option>
              <option value="2">Composite</option>
              <option value="3">Unregistered</option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">GSTIN:</label>
          <div class="col-md-2">
            <input type="text" class="form-control" placeholder="GSTIN" maxlength="15" v-if="distributor" v-model="distributor.gstin">
          </div>
        </div>

<!--        <div class="form-group row">-->
<!--          <label class="col-md-2 col-form-label">Owner Name:</label>-->
<!--          <div class="col-md-10">-->
<!--            <input type="text" class="form-control" placeholder="Owner Name" v-if="distributor" v-model="distributor.owner" autocomplete="off">-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="form-group row">-->
<!--          <label class="col-md-2 col-form-label">DOB:</label>-->
<!--          <div class="col-md-4">-->
<!--            <input type="date" class="form-control" v-if="distributor" v-model="distributor.dob" />-->
<!--          </div>-->

<!--          <label class="col-md-1 col-form-label">DOW:</label>-->
<!--          <div class="col-md-5">-->
<!--            <input type="date" class="form-control" v-if="distributor" v-model="distributor.dow" />-->
<!--          </div>-->
<!--        </div>-->

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Incharge:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="Incharge Name" maxlength="50" autocomplete="off" v-if="distributor" v-model="distributor.incharge_name">
          </div>

          <label class="col-md-1 col-form-label">Mobile:</label>
          <div class="col-md-5">
            <input type="tel" class="form-control" placeholder="Mobile" maxlength="10" v-if="distributor" v-model="distributor.incharge_mobile">
          </div>
        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-primary legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'DistributorForm',
  store,
  data() {
    return {
      distributor: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}'),
      areas : [],
    }
  },
  component: {

  },
  props: {
    mydistributor: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}')
    }
  },
  beforeMount () {
    this.distributor = this.mydistributor; // save props data to itself's data and deal with tms
    // this.distributor.is_crdr = (this.distributor.opening_balance > 0 ? 1 : -1);
    // this.distributor.opening_balance = Math.abs(this.distributor.opening_balance);
  },
  created () {
    this.$data.distributor = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}')
  },
  mounted () {
    this.loadAreas();
    $('.form-control-select2').select2()
    $('#txtname').focus();
  },
  methods: {
    closeWindow(){
      this.distributor = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}');
      this.$emit("distributor_window_closed");
    },
    loadAreas () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };
      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.areas = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/areas`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data) ){
            self.$data.areas = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      })

    },
    saveDocument(){

      const self = this;

      self.$data.distributor.type = parseInt(self.$data.distributor.type);
      if(self.$data.distributor.name.toString().length< 5){
        alert("Invalid Name");
        return;
      }else if( parseInt(self.$data.distributor.state_cd) < 1 ){
        alert("Invalid State selected");
        return;
      }

      self.$data.distributor.state_cd = parseInt(self.$data.distributor.state_cd);
      self.$data.distributor.opening_balance =  $('#cmbcrdr').val() *  Math.abs(parseFloat(self.$data.distributor.opening_balance));

      const requestOptions = {
        method:  (self.$data.distributor.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.distributor)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/distributor`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.$emit('distributor_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
